import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { NavbarItem } from './navbar-item';

const navbarItems = [
  {
    title: 'Team',
    link: '/team',
  },
  {
    title: 'Calendar',
    link: '/calendar',
  },
  {
    title: 'Updates',
    link: '/executive-committee-updates',
  },
  {
    title: 'Events',
    link: '/events',
  },
  {
    title: 'Footprints',
    link: '/footprints',
  },
  {
    title: 'Donations',
    link: '/school-project-donation',
  },
];

export const Navbar = ({ addShadow }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className={`bg-white sticky top-0 z-50 ${addShadow && 'shadow-md'}`}>
      <div className="max-w-screen-xl mx-auto px-4 xl:px-0">
        <div className="relative flex items-center justify-between h-20">
          <div className="absolute inset-y-0 left-2 flex items-center lg:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Desktop nav items */}
          <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-between mt-4">
            <div className="flex-shrink-0 flex items-center">
              {/* Website Logo */}
              <Link to="/" className="flex items-center">
                <StaticImage
                  src="../../images/logo.png"
                  width={48}
                  alt="LBYG Logo"
                />
                <h1 className="font-heading text-xl ml-2 font-bold hidden xl:block tracking-tight">
                  Lankarama Buddhist Youth Group
                </h1>
              </Link>
            </div>
            <div className="hidden lg:block md:ml-6">
              <div className="flex space-x-4">
                {navbarItems.map((navItem) => (
                  <NavbarItem
                    key={navItem.title}
                    title={navItem.title}
                    link={navItem.link}
                  />
                ))}
                <Link
                  className="flex items-center justify-center px-4 py-2 border-2 border-transparent text-base font-bold rounded-md bg-primary hover:bg-secondary shadow-md"
                  aria-label={`Signup to LBYG`}
                  to={'/signup'}
                >
                  Join LBYG
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navbarItems.map((navItem) => (
              <NavbarItem
                key={navItem.title}
                title={navItem.title}
                link={navItem.link}
                isMobile
              />
            ))}
            <Link
              className="flex items-center justify-center px-4 py-2 border-2 border-transparent text-base font-bold rounded-md bg-primary hover:bg-secondary shadow-md"
              aria-label={`Signup to LBYG`}
              to={'/signup'}
            >
              Join LBYG
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};
