import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulFooter {
            title
            description {
              description
            }
            facebookUrl
            instagramUrl
            youtubeUrl
          }
        }
      `}
      render={(data) => (
        <footer className="bg-primary p-4">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="flex-grow text-center sm:text-left">
                <p className="font-semibold text-gray-800">
                  © {data.contentfulFooter.title}
                </p>
                <p className="text-sm text-gray-600 mt-2">
                  {data.contentfulFooter.description.description}
                </p>
              </div>
              <div className="flex mt-2 sm:mt-0 ml-2">
                <a
                  href={data.contentfulFooter.facebookUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-blueDark"
                >
                  <FaFacebook size="2em" />
                </a>
                <a
                  href={data.contentfulFooter.instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 hover:text-pinkDark"
                >
                  <FaInstagram size="2em" />
                </a>
                <a
                  href={data.contentfulFooter.youtubeUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 hover:text-redDark"
                >
                  <FaYoutube size="2em" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
