import React, { useEffect, useState } from 'react';
import Footer from '../footer/footer';
import { Navbar } from '../navbar/navbar';
import SearchEngineOptimization from '../seo';

export const Layout = ({
  children,
  title,
  image,
  description,
  styles = '',
  isWide = false,
}) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.scrollY);
    };
  }, []);

  return (
    <div
      className={`leading-relaxed tracking-wide flex flex-col min-h-screen ${styles}`}
    >
      <SearchEngineOptimization
        title={title}
        image={image}
        description={description}
      />
      <Navbar addShadow={offset > 0} />
      <div
        className={`${
          isWide ? '' : 'container max-w-screen-xl mx-auto flex-grow md:p-4'
        }`}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};
